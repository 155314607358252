import * as React from "react"
import { Override, addPropertyControls, ControlType } from "framer"

// Create a hook that can be used in any component
export function useNoScroll() {
    React.useEffect(() => {
        // Save the original overflow style
        const originalStyle = window.getComputedStyle(document.body).overflow

        // Disable scrolling
        document.body.style.overflow = "hidden"

        // Clean up function to restore scrolling when component unmounts
        return () => {
            document.body.style.overflow = originalStyle
        }
    }, [])
}

// This is the main override function
export function NoScrollOverride(): Override {
    return (props) => {
        useNoScroll()
        return props
    }
}

// Make sure to export as default for Framer to detect it
export default NoScrollOverride

// For use with higher-order components
export function withNoScroll(Component) {
    return (props) => {
        useNoScroll()
        return <Component {...props} />
    }
}
